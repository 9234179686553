/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import axios from 'axios';

// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import PlayMetadata from '../PlayMetadata';
import PlaySessions from '../PlaySessions';
import Spinner from '../../../shared/UIKit/Spinner';

// Constants
import { SUCCESSFUL_RESPONSE_CODE } from '../../../shared/constants';

// Context
import { DateContext } from '../../../context/DateContext';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                   Component                                */
/* -------------------------------------------------------------------------- */

function PlayLayout({ id }) {
  // Context
  const context = useContext(DateContext);

  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [play, setPlay] = useState();

  // Callbacks
  const fetchPlay = useCallback(async () => {
    try {
      const TARGET_ENDPOINT = `${
        process.env.GATSBY_API_URL
      }/product/play/${id}?eventsStartingOn=${format(
        new Date(context.selectedDate),
        'yyyy-MM-dd',
      )}`;

      const result = await axios.get(TARGET_ENDPOINT);

      if (result.status === SUCCESSFUL_RESPONSE_CODE) {
        setPlay(result.data);
      } else {
        setError(
          "Une erreur s'est produite. Merci de réessayer ultérieurement.",
        );
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Une erreur s'est produite. Merci de réessayer ultérieurement.");
    }
  }, [id, context.selectedDate]);

  const renderPlay = () => {
    return (
      <Container className="fluid" fluid>
        <PlayMetadata play={play} />
        <PlaySessions sessions={play?.events} />
      </Container>
    );
  };

  // Effets
  useEffect(() => {
    fetchPlay();
  }, [context?.selectedDate, fetchPlay]);

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="fluid play-layout" fluid>
      {!loading ? (
        <>{!error ? <>{renderPlay()}</> : <h2 className="error">{error}</h2>}</>
      ) : (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
    </Container>
  );
}

PlayLayout.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PlayLayout;
