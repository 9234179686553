/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Redirect } from '@reach/router';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import MovieLayout from '../page-components/Movie/MovieLayout';
import PlayLayout from '../page-components/Play/PlayLayout';
import ShowLayout from '../page-components/Show/ShowLayout';
import ExhibitionLayout from '../page-components/Exhibition/ExhibitionLayout';

/* -------------------------------------------------------------------------- */
/*                                 Movie Page                                */
/* -------------------------------------------------------------------------- */

function Page({ params }) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  const customRouter = () => {
    const { productType, productId } = params;
    switch (productType) {
      case 'movie':
        return <MovieLayout id={productId} />;
      case 'play':
        return <PlayLayout id={productId} />;
      case 'gig':
        return <ShowLayout id={productId} />;
      case 'exhibition':
        return <ExhibitionLayout id={productId} />;
      default:
        return <Redirect to="/" />;
    }
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={t('title')} />
      {customRouter()}
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["MoviePage", "ShowPage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

Page.propTypes = {
  params: PropTypes.oneOfType([PropTypes.object]),
};
Page.defaultProps = {
  params: undefined,
};

export default Page;
