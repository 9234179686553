/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// UI lib comoponents
import { Container } from 'react-grid-system';
import Modal from '../../../shared/UIKit/Modal/Modal';
import ImageBox from '../../../shared/UIKit/ImageBox';

// local images
import PLAY_ICON from '../../../images/icons/play-button.svg';
// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function MovieMetadata({ movie }) {
  /* ---------------------------------- HOOKS --------------------------------- */

  //   Localization
  const { t } = useTranslation('Common');

  const [openModal, setOpenModal] = useState(false);

  /* ******************************** CONSTANTS ******************************* */

  /* eslint-disable indent */
  const duration = movie.duration
    ? ` (${Math.floor(movie.duration / 60)}h ${movie.duration % 60}
              min)`
    : ` (${t('notSpecified')})`;

  const date = movie.releaseDate
    ? format(new Date(movie.releaseDate), 'd MMMM Y', {
        locale: fr,
      })
    : ` (${t('notSpecified')})`;
  /* eslint-enable indent */

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="movie-metadata fluid" fluid>
      <Container className="fluid left-content">
        <Container className="actions fluid">
          {movie.previewExternalVideo && (
            <Modal
              isOpen={openModal}
              setIsOpen={setOpenModal}
              title={`${t('cards.film.trailer')} ${movie.title}`}
            >
              <iframe
                title="YoutubeVideo"
                style={{ height: '55vh', width: '100%', border: 'none' }}
                src={movie.previewExternalVideo.replace('watch?v=', 'embed/')}
              />
            </Modal>
          )}
          <h1>{movie.title}</h1>
          <Container className="movie-buttons fluid">
            <a href="#movie-sessions" className="sessions-link">
              {t('cards.film.sessions')}
            </a>
            {movie.previewExternalVideo && (
              <button
                type="button"
                className="trailers"
                onClick={() => setOpenModal(true)}
              >
                <img src={PLAY_ICON} alt="play trailer" />
                <p>{t('cards.film.trailers')}</p>
              </button>
            )}
          </Container>
        </Container>
        <Container className="fluid">
          <div className="meta">
            <p>
              <b>{t('movieMetadata.releaseDate')}</b>
              <span>
                {date}
                {duration}
              </span>
            </p>
            <p>
              <b>{t('movieMetadata.by')}</b>
              <span>{movie.directors || t('notSpecified')}</span>
            </p>
            <p>
              <b>{t('movieMetadata.with')}</b>
              <span>{movie.actors || t('notSpecified')}</span>
            </p>
            <p>
              <b>{t('movieMetadata.genres')}</b>
              <span>{movie.genres || t('notSpecified')}</span>
            </p>
            <p>
              <b>{t('movieMetadata.countries')}</b>
              <span>{movie.countries || t('notSpecified')}</span>
            </p>
          </div>
        </Container>
        <Container className="fluid">
          <div className="description">
            <ReactMarkdown>{movie.synopsis || t('notSpecified')}</ReactMarkdown>
          </div>
        </Container>
      </Container>

      <div className="fluid gap" />

      {movie.cover && (
        <Container className="image-container fluid">
          <ImageBox
            image={movie.cover.large ?? movie.cover.original ?? movie.cover}
            alt={movie.title}
          />
        </Container>
      )}
    </Container>
  );
}

MovieMetadata.propTypes = {
  movie: PropTypes.shape({
    title: PropTypes.string,
    previewExternalVideo: PropTypes.string,
    directors: PropTypes.string,
    actors: PropTypes.string,
    genres: PropTypes.string,
    countries: PropTypes.string,
    synopsis: PropTypes.string,
    cover: PropTypes.objectOf(PropTypes.string),
    releaseDate: PropTypes.string,
    duration: PropTypes.number,
  }).isRequired,
};

export default MovieMetadata;
