/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import axios from 'axios';

// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import MovieMetadata from '../MovieMetadata';
import MovieSessions from '../MovieSessions';
import Spinner from '../../../shared/UIKit/Spinner';

// Constants
import { SUCCESSFUL_RESPONSE_CODE } from '../../../shared/constants';

// Context
import { DateContext } from '../../../context/DateContext';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                   Component                                */
/* -------------------------------------------------------------------------- */

function MovieLayout({ id }) {
  // Context
  const context = useContext(DateContext);

  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [movie, setMovie] = useState(null);

  // Callbacks
  const fetchMovie = useCallback(async () => {
    try {
      const response = await axios.get(
        `${
          process.env.GATSBY_API_URL
        }/product/movie/${id}?eventsStartingOn=${format(
          new Date(context?.selectedDate),
          'yyyy-MM-dd',
        )}`,
      );

      if (response.status === SUCCESSFUL_RESPONSE_CODE) {
        setMovie(response.data);
      } else {
        setError(
          "Une erreur s'est produite. Merci de réessayer ultérieurement.",
        );
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Une erreur s'est produite. Merci de réessayer ultérieurement.");
    }
  }, [context?.selectedDate, id]);

  const renderMovie = () => {
    return (
      <Container className="fluid" fluid>
        <MovieMetadata movie={movie} />
        {movie && (
          <MovieSessions
            date={new Date(context?.selectedDate || Date.now()).toISOString()}
            events={movie?.events}
            image={movie?.secondaryCover}
          />
        )}
      </Container>
    );
  };

  // Effets
  useEffect(() => {
    fetchMovie();
  }, [fetchMovie]);

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="fluid movie-layout" fluid>
      {!loading ? (
        <>
          {!error ? <>{renderMovie()}</> : <h2 className="error">{error}</h2>}
        </>
      ) : (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
    </Container>
  );
}

MovieLayout.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MovieLayout;
