/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI lib comoponents
import { Container } from 'react-grid-system';
import ImageBox from '../../../shared/UIKit/ImageBox';

// Helpers & utils
import { getFormattedDate } from '../../../shared/Helpers/utils';

// Local images
import MAP_ICON from '../../../images/icons/map.svg';
import CALENDAR_ICON from '../../../images/icons/calendar.svg';
import CLOCK_ICON from '../../../images/icons/clock.svg';
import TICKET_ICON from '../../../images/icons/ticket.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function ShowMetadata({ product }) {
  /* ---------------------------------- HOOKS --------------------------------- */

  //   Localization
  const { t } = useTranslation('Common');

  /* ******************************** CONSTANTS ******************************* */

  const { GATSBY_GOOGLE_MAPS_URL } = process.env;
  const fallbackElement = ` ${t('notSpecified')}`;

  const { title, cover, events, description } = product;

  /* ******************************** RENDERING ******************************* */

  return (
    <>
      <Container className="show-metadata fluid" fluid>
        <Container className="important-metadata">
          <h1>{title}</h1>

          {(events ?? []).map((event) => (
            <div className="actions">
              <p>
                <object data={MAP_ICON} type="image/svg+xml">
                  map icon
                </object>
                <b>{event.venue.name || t('notSpecified')}</b>
                <a
                  target="_blank"
                  href={`${GATSBY_GOOGLE_MAPS_URL}/search/?api=1&query=${event.venue.coordinates}`}
                  rel="noreferrer"
                >
                  {t('showMetadata.map')}
                </a>
              </p>
              <p>
                <object data={CALENDAR_ICON} type="image/svg+xml">
                  calendar icon
                </object>
                <b>{t('showMetadata.from')}</b>
                <span>{getFormattedDate(event.start, fallbackElement)}</span>
              </p>
              <p>
                <object data={CLOCK_ICON} type="image/svg+xml">
                  clock icon
                </object>
                <b>{t('showMetadata.to')}</b>
                <span>{getFormattedDate(event.end, fallbackElement)}</span>
              </p>
              <p>
                <object data={TICKET_ICON} type="image/svg+xml">
                  ticket icon
                </object>
                <a href={event.venue.purchaseUrl}>
                  {event.venue.purchaseUrl || t('notSpecified')}
                </a>
              </p>
            </div>
          ))}
        </Container>
        <Container className="image-container">
          <ImageBox image={cover} alt="show thumbnail" />
        </Container>
      </Container>
      <Container className="show-program">
        <div className="sessions-wrapper">
          <h2>{t('sections.description')}</h2>
          <h2>{title}</h2>

          <ReactMarkdown>{description || t('notSpecified')}</ReactMarkdown>
        </div>
        <div className="image-container">
          <ImageBox image={cover} alt="session thumbnail" />
        </div>
      </Container>
    </>
  );
}

ShowMetadata.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string,
    cover: PropTypes.string,
    description: PropTypes.string,
    artists: PropTypes.string,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
        venue: PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
          coordinates: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};

export default ShowMetadata;
