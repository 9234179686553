/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// UI lib comoponents
import { Container } from 'react-grid-system';
import ImageBox from '../../../shared/UIKit/ImageBox';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function MovieSessions({ events, image }) {
  /* ******************************** CONSTANTS ******************************* */

  const groupedEvents = events.reduce((acc, event) => {
    const eventsGroup = { ...acc };
    const formattedDate = format(new Date(event.start), 'EEEE d MMMM Y', {
      locale: fr,
    });

    let venuesGroup = eventsGroup[formattedDate]?.[event.venue.slug];
    if (venuesGroup) {
      venuesGroup = [...venuesGroup, event];
    } else {
      venuesGroup = [event];
    }

    eventsGroup[formattedDate] = {
      ...eventsGroup[formattedDate],
      [event.venue.slug]: venuesGroup,
    };

    return eventsGroup;
  }, {});

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="movie-sessions" style={{ display: 'flex' }} fluid>
      <div className="sessions fluid">
        {Object.entries(groupedEvents).map(([eventDate, session]) => (
          <>
            <h1>{eventDate}</h1>
            <div className="sessions-container">
              <div className="sessions-wrapper">
                {Object.entries(session).map(
                  ([slugifiedVenue, venueEvents]) => (
                    <div className="session" key={slugifiedVenue}>
                      <a href={`/venue/${slugifiedVenue}`}>
                        <h3>{venueEvents[0].venue.name}</h3>
                      </a>
                      {venueEvents.map(({ start, language }) => (
                        <button
                          className="session-button flex justify-center items-center"
                          type="button"
                        >
                          <b>{new Date(start).toTimeString().slice(0, 5)}</b>
                          <span>{language}</span>
                        </button>
                      ))}
                    </div>
                  ),
                )}

                <hr className="seperator" />
              </div>
            </div>
          </>
        ))}
      </div>
      {image ? (
        <div className="image-container">
          <ImageBox image={image} alt="session thumbnail" />
        </div>
      ) : null}
    </Container>
  );
}

MovieSessions.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      uniqueId: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      purchaseUrl: PropTypes.string.isRequired,
      venue: PropTypes.shape({
        name: PropTypes.string.isRequired,
        uniqueId: PropTypes.string.isRequired,
      }).isRequired,
      category: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  image: PropTypes.string.isRequired,
};

export default MovieSessions;
