/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

// UI lib components
import { Container } from 'react-grid-system';

// UI local components

import TICKETS_ICON from '../../../images/icons/tickets.png';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               Theater Component                            */
/* -------------------------------------------------------------------------- */

function PlaySessions({ sessions }) {
  /* ***************************** LOCAL VARIABLES **************************** */
  const groupedSessions = sessions.reduce((acc, session) => {
    const eventsGroup = { ...acc };
    const formattedDate = format(new Date(session.start), 'EEEE d MMMM Y');

    let venuesGroup = eventsGroup[formattedDate]?.[session.venue.slug];
    if (venuesGroup) {
      venuesGroup = [...venuesGroup, session];
    } else {
      venuesGroup = [session];
    }

    eventsGroup[formattedDate] = {
      ...eventsGroup[formattedDate],
      [session.venue.slug]: venuesGroup,
    };

    return eventsGroup;
  }, {});

  /* ***************************** RENDER HELPERS ***************************** */
  function renderTicketElement(ticket) {
    if (ticket) {
      return (
        <Container className="detail flex items-center fluid">
          <a className="ticket" href={ticket} target="_blank" rel="noreferrer">
            <img src={TICKETS_ICON} alt="" />
          </a>
        </Container>
      );
    }
    return null;
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="play-sessions" fluid>
      <div className="sessions fluid">
        {Object.entries(groupedSessions).map(([eventDate, session]) => (
          <>
            <h1>{eventDate}</h1>
            <div className="sessions-container">
              <div className="sessions-wrapper">
                {Object.entries(session).map(
                  ([slugifiedVenue, venueEvents]) => (
                    <div className="session" key={slugifiedVenue}>
                      <a href={`/venue/${slugifiedVenue}`}>
                        <h3>{venueEvents[0].venue.name}</h3>
                      </a>
                      {venueEvents.map(({ start, language, purchaseUrl }) => (
                        <button
                          className={`${
                            purchaseUrl
                              ? 'session-button'
                              : 'session-button-disabled'
                          } flex justify-center items-center`}
                          type="button"
                        >
                          <b>{format(new Date(start), 'HH:mm')}</b>
                          <span>{language}</span>
                          {renderTicketElement(purchaseUrl)}
                        </button>
                      ))}
                    </div>
                  ),
                )}

                <hr className="seperator" />
              </div>
            </div>
          </>
        ))}
      </div>
    </Container>
  );
}

PlaySessions.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      uniqueId: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      purchaseUrl: PropTypes.string.isRequired,
      venue: PropTypes.shape({
        name: PropTypes.string.isRequired,
        uniqueId: PropTypes.string.isRequired,
      }).isRequired,
      category: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default PlaySessions;
