/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI lib components
import { Col, Container } from 'react-grid-system';

// UI local components
import ImageBox from '../../../shared/UIKit/ImageBox';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                Banner Component                            */
/* -------------------------------------------------------------------------- */

function PlayMetadata({ play }) {
  /* ********************************** HOOKS ********************************* */

  //   Localization
  const { t } = useTranslation('Common');

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="play-metadata flex ietms-center fluid" fluid>
      <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className="left-side">
        <p className="title">{play.title}</p>
        <div className="details fluid">
          <p>
            <b>{t('playMetadata.staging')}</b>
            <span>{play.directors || t('notSpecified')}</span>
          </p>
          <p>
            <b>{t('playMetadata.distribution')}</b>
            <span>{play.actors || t('notSpecified')}</span>
          </p>
          <p>
            <b>{t('playMetadata.genres')}</b>
            <span>
              {play.types
                ? play.types.map((ty) => ty.name).join(', ')
                : t('notSpecified')}
            </span>
          </p>
          <p>
            <b>{t('playMetadata.countries')}</b>
            <span>{play.countries || t('notSpecified')}</span>
          </p>
        </div>
        <div className="description fluid">
          <span>{t('playMetadata.synopsis')}</span>
          <ReactMarkdown>{play.synopsis || t('notSpecified')}</ReactMarkdown>
        </div>
      </Col>
      <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className="right-side">
        <ImageBox
          style={{ objectFit: 'contain' }}
          image={play.cover}
          alt="play product thumbnail"
        />
      </Col>
    </Container>
  );
}

PlayMetadata.propTypes = {
  play: PropTypes.shape({
    title: PropTypes.string,
    cover: PropTypes.string,
    actors: PropTypes.string,
    directors: PropTypes.string,
    types: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, color: PropTypes.string }),
    ),
    countries: PropTypes.string,
    synopsis: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
};

export default PlayMetadata;
